<template>
    <v-container class="pa-8">
    <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
        <v-icon>mdi-card-account-details-outline</v-icon>
        User Accounts 
        <v-btn color="primary" class="float-right"  @click="newUserDialog = true,isEdit = false">
            <v-icon class="mr-2">mdi-account-plus</v-icon>
            ADD NEW USER
        </v-btn>
    </div>
    <v-row> 
        <v-col class="col-4">
            <v-card :elevation="0">
                <v-toolbar
                flat
                dense
                color="primary"
                dark
                >
                    <v-toolbar-title class="text-button">DEPARTMENT</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="showActions ? showActions = !showActions : ''"
                        >
                        <v-icon v-if="showActions">mdi-close</v-icon>
                        <v-icon v-else>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="addDepartment">
                            <v-list-item-title>Add Department</v-list-item-title>
                        </v-list-item> 
                        <v-list-item @click="showActions = !showActions">
                            <v-list-item-title>{{showActions ? 'Hide' : 'Show'}} Actions</v-list-item-title>
                        </v-list-item> 
                    </v-list>                     
                    </v-menu>
                </v-toolbar>

            <v-tabs vertical v-model="tab">
                <v-tab v-for="dept in Departments" :key="dept.departmentName"  active-class="text-primary primary lighten-5">
                    {{dept.departmentName}}
                    <div class="ml-4" v-show="showActions && dept.departmentName !== 'RISK MANAGEMENT TEAM'">
                        <v-btn x-small icon @click="editDept(dept)" color="grey">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn x-small icon @click="deleteDept(dept)" color="grey">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
            
                    </div>
                </v-tab>
            </v-tabs>
      </v-card>
      </v-col>
        <v-col class="col">
            <v-card :elevation="0">
                <v-toolbar
                flat
                dense
                color="dark"
                dark
                v-if="returnSelectedDept"
                >
                    <v-toolbar-title class="text-button" v-if="returnSelectedDept">{{returnSelectedDept}} USER ACCOUNTS</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="newUserDialog = true,isEdit = false">
                                <v-icon>mdi-account-plus</v-icon>         
                            </v-btn>
                        </template>
                        <span v-if="returnSelectedDept">Add New {{returnSelectedDept}} User Account</span>
                    </v-tooltip> -->
                </v-toolbar>
                <v-data-table
                    :headers="headers"
                    :items="returnUserAccounts"
                    :items-per-page="5"
                    :elevation="0"
                >
                    <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editUser(item)"
                        v-show="checkIfUserIsLoggedIn(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteUser(item)"
                        v-show="checkIfUserIsLoggedIn(item)"
                    >
                        mdi-delete
                    </v-icon>
                    </template>           
                </v-data-table> 
            </v-card>
        </v-col>
    </v-row>

    <v-dialog
      v-model="newUserDialog"
      persistent
      max-width="500"
    >
        <v-card>
            <v-card-title class="headline">
                <span>{{isEdit ? 'Update User' : 'Add New User'}}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="newUserDialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>  
            <v-card-text class="mt-4" v-if="!isEdit">
                <v-text-field
                    class="mb-2"
                    type="text"
                    placeholder="Enter PETNET email"
                    outlined
                    suffix="@petnet.com.ph"
                    v-model="newEmail"
                    :hint="newEmail ? `This will be saved as ${newEmail}@petnet.com.ph.` : ''"
                ></v-text-field>
                <v-select
                    v-model="newUserRole"
                    :items="itemsRole"
                    label="User Role"
                    placeholder="Select Role"
                    outlined
                ></v-select>
                <v-select
                    v-model="selectedDept"
                    :items="returnMapDepartments"
                    label="Department/Section"
                    multiple
                    placeholder="Select Department"
                    outlined
                    @input="newUserRole == 'Process Owner' ? selectedDept.splice(1,1) : ''"
                ></v-select>

                <v-select
                    v-model="selectedProduct"
                    :items="returnMapProduct"
                    label="Product (PRMP)"
                    multiple
                    placeholder="Select Product (PRMP)"
                    outlined
                    @input="newUserRole == 'Process Owner' ? selectedDept.splice(1,1) : ''"
                ></v-select>

            </v-card-text>
            <v-card-text class="mt-4" v-else>
                <v-text-field
                    v-if="selectedUser"
                    class="mb-2"
                    type="text"
                    placeholder="Enter PETNET email"
                    outlined
                    suffix="@petnet.com.ph"
                    v-model="selectedUser.emailAdd"
                    :hint="newEmail ? `This will be saved as ${newEmail}@petnet.com.ph.` : ''"
                    disabled
                ></v-text-field>
                <v-select
                    v-if="selectedUser"
                    v-model="selectedUser.userRole"
                    :items="itemsRole"
                    label="User Role"
                    placeholder="Select Role"
                    outlined
                ></v-select>
                
                <v-select
                    v-if="selectedUser"
                    v-model="selectedUser.departmentSections"
                    :items="returnMapDepartments"
                    label="Department/Section"
                    multiple
                    placeholder="Select Department"
                    outlined
                    @input="selectedUser.userRole == 'Process Owner' ? selectedUser.departmentSections.splice(1,1) : ''"
                ></v-select>

                <v-select
                    v-if="selectedUser"
                    v-model="selectedUser.products"
                    :items="returnMapProduct"
                    label="Product (PRMP)"
                    multiple
                    placeholder="Select Product (PRMP)"
                    outlined
                    @input="newUserRole == 'Process Owner' ? selectedDept.splice(1,1) : ''"
                ></v-select>

            </v-card-text>
            <v-card-actions class="pa-2">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="newUserDialog = false,newEmail = null,selectedDept = []"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="newUserDialog = false,addNewUser()"
                >
                    {{isEdit ? 'Update User' : 'Save New User'}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    </v-container>
</template>
<script>
export default {
    data: () => ({
        tab: 0,
        newUserDialog: false,
        newEmail: null,
        newUserRole: 'Process Owner',
        itemsRole: ['Department Head','Process Owner'],
        headers: [
            { text: 'Email', sortable: true, value: 'emailAdd' },
            { text: 'Role', sortable: true, value: 'userRole' },
            { text: 'Actions', sortable: false, value: 'actions' }
        ],
        selectedDept: [],
        selectedProduct: [],
        isEdit: false,
        selectedUser: null,
        showActions: false,
    }),
    firestore(){
      return {
        Departments: this.$db.collection('Departments'),
        Product: this.$db.collection('Product'),
        UserAccounts: this.$db.collection('UserAccounts'),
      }
    },
    computed:{
        returnMapDepartments(){
            return this.Departments.map(a=>{
                return a.departmentName
            })
        },
        returnMapProduct(){
            return this.Product.map(a=>{
                return a.Product
            })
        },
        returnSelectedDept(){
            if(this.Departments[this.tab]) return this.Departments[this.tab].departmentName
            return null
        },
        returnUserAccounts(){
            return this.UserAccounts.filter(a=>{
                return a.department == this.returnSelectedDept
            })
        },
    },
    methods: {
        async addDepartment(){
            let res = await this.$dialog.prompt({
                text: `Add New Department`,
                title: `New Dapartment`,
            })      
            
            if(!res) return 
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to add ${res} to Departments?`,
                title: `Confirm New Department`
            })

            if(confirm){   
                this.$db.collection('Departments').add({departmentName: res})
                .then(()=>{
                    this.$dialog.notify.success(`New ${res} Department Added`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                     
                })                   
            }         
        },
        async addNewUser(){
            let editItem = this.selectedUser
            let self = this

            console.log(self.isEdit,'is Edit');
            if(!self.isEdit && (self.newEmail == null || self.selectedDept.length == 0 )) {
                self.$dialog.notify.error(`Email should not be empty`, {
                    position: 'bottom-right',
                    timeout: 3000
                })     
                self.newUserDialog = true 
                return              
            }

            let res = self.newEmail+'@petnet.com.ph'

            const confirm = await self.$dialog.confirm({
                text: self.isEdit ? `Do you want to update ${editItem.emailAdd} ?` : `Do you really want to add ${res} under ${self.returnSelectedDept}?`,
                title: `Confirm ${self.isEdit ? 'Update' : 'New'} User`
            })

            if(confirm){   
                let newUser = {
                    departmentSections: self.selectedDept,
                    department: self.selectedDept[0],
                    emailAdd: res,
                    userRole: self.newUserRole,
                    products: self.selectedProduct
                }
                
                if(self.isEdit){
                    let objUpdate = {...editItem}
                    let key = objUpdate['.key']
                    delete objUpdate['.key']
                    objUpdate.department = objUpdate.departmentSections[0]
                    objUpdate.emailAdd = objUpdate.emailAdd+'@petnet.com.ph'

                    self.$db.collection('UserAccounts').doc(key).set(objUpdate)
                    .then(()=>{
                        self.$dialog.notify.success(`Updated ${objUpdate.emailAdd}`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })                     
                    })
                } else {
                    self.$db.collection('UserAccounts').add(newUser)
                    .then(()=>{
                        self.$dialog.notify.success(`New User Added`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })                     
                    })
                }
                   
            } else {
                self.newEmail = null
                self.newUserRole = 'Member'
                self.isEdit = false
                self.selectedUser = null
                self.selectedDept = []
                self.selectedProduct = []
            }
        },
        editUser(item){
            this.isEdit = true
            let editData = {...item}
            editData.products = editData.products == null ? [] : editData.products
            let arr = editData.emailAdd.split('@')
            editData.emailAdd = arr[0]
            this.selectedUser = editData
            console.log(this.selectedUser,'user');
            this.newUserDialog = true
        },
        async deleteUser(item){

            const confirm = await this.$dialog.confirm({
                text: `Do you really want to delete this user?`,
                title: `Confirm Delete ${item.emailAdd}`
            })

            if(confirm){
                 this.$db.collection('UserAccounts').doc(item['.key']).delete()
                 .then(()=>{
                    this.$dialog.notify.success(`Deleted User from ${item.department}`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                     
                 })
            }            
        },
        async editDept(dept){
            let self = this
            let res = await self.$dialog.prompt({
                text: `Update Department`,
                title: `Update ${dept.departmentName} Dapartment`,
                value: dept.departmentName
            })      
            
            if(!res) return 

            let filter = self.UserAccounts.filter(a=>{
                return dept.departmentName == a.department
            })

            const confirm = await self.$dialog.confirm({
                text: `Do you really want to update this ${dept.departmentName} DEPARTMENT'S name to ${res}? This will also update it to the users under this department.`,
                title: `Confirm Update`
            })   

            if(confirm){
                self.$db.collection('Departments').doc(dept['.key']).update({departmentName: res})
                .then(async()=>{
                    await self.updateUsersDeptName(res,filter)
                    self.$dialog.notify.success(`Updated Department`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })  
                    location.reload()  
                    self.showActions = false                 
                })
            }
        },
        updateUsersDeptName(res,filter){
            filter.forEach(a=>{
                this.$db.collection('UserAccounts').doc(a['.key']).update({
                    department: res
                })
            })
        },
        async deleteDept(dept){
            let self = this

            if(self.checkIfUserIsLoggedIn(null,dept.departmentName)){
                self.$dialog.error({
                    text: `You can't delete this department. Current user is under this department.`,
                    title: 'Department Delete Denied'
                })                 
                return
            }

            let filter = self.UserAccounts.filter(a=>{
                return dept.departmentName == a.department
            })

            const confirm = await self.$dialog.confirm({
                text: `Do you really want to delete department ${dept.departmentName}. This will also delete users under it and will lose access to the system.`,
                title: `Confirm Update`
            })   

            if(confirm){
                self.$db.collection('Departments').doc(dept['.key']).delete()
                .then(async ()=>{
                    await self.deleteUsersUnderDept(filter)
                    self.$dialog.notify.success(`Deleted Department`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })  
                    location.reload()  
                    self.showActions = false                 
                })
            }
        },
        deleteUsersUnderDept(filter){
            filter.forEach(a=>{
                this.$db.collection('UserAccounts').doc(a['.key']).delete()
            })            
        },
        checkIfUserIsLoggedIn(user = null,deptName = null){
            let userAccount = this.$store.getters['useraccount/isAuthenticated']
            let emailAdd = userAccount.email

            if(user){
                return user.emailAdd !== emailAdd
            }

            if(deptName){
                let filter = this.UserAccounts.filter(a=>{
                    return deptName == a.department
                })

                return this.$lodash.findIndex(filter, a=>{ return a.emailAdd == emailAdd}) > -1
            }
        }
    }   
}
</script>
<style scoped>

div .v-tab  {
    justify-content: left;
}

</style>
